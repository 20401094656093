import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9ff2c3a2 = () => interopDefault(import('../pages/beli.vue' /* webpackChunkName: "pages/beli" */))
const _4e8bf1c4 = () => interopDefault(import('../pages/cari.vue' /* webpackChunkName: "pages/cari" */))
const _24c8b4af = () => interopDefault(import('../pages/pesan/index.vue' /* webpackChunkName: "pages/pesan/index" */))
const _57fef806 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1d00e869 = () => interopDefault(import('../pages/term-condition.vue' /* webpackChunkName: "pages/term-condition" */))
const _2c2e1d68 = () => interopDefault(import('../pages/verification-promo.vue' /* webpackChunkName: "pages/verification-promo" */))
const _1fb9def8 = () => interopDefault(import('../pages/merchant-pos/privacy-policy.vue' /* webpackChunkName: "pages/merchant-pos/privacy-policy" */))
const _647288c4 = () => interopDefault(import('../pages/merchant-pos/terms-condition.vue' /* webpackChunkName: "pages/merchant-pos/terms-condition" */))
const _7060a161 = () => interopDefault(import('../pages/pesan/info.vue' /* webpackChunkName: "pages/pesan/info" */))
const _5b839eb3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ed3af0a4 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _2f997ff4 = () => interopDefault(import('../pages/_slug/cart.vue' /* webpackChunkName: "pages/_slug/cart" */))
const _311d5dcc = () => interopDefault(import('../pages/_slug/checkout.vue' /* webpackChunkName: "pages/_slug/checkout" */))
const _39d0f282 = () => interopDefault(import('../pages/_slug/info.vue' /* webpackChunkName: "pages/_slug/info" */))
const _57778e6d = () => interopDefault(import('../pages/_slug/morak-letter.vue' /* webpackChunkName: "pages/_slug/morak-letter" */))
const _0ea9872d = () => interopDefault(import('../pages/_slug/order/index.vue' /* webpackChunkName: "pages/_slug/order/index" */))
const _d2841986 = () => interopDefault(import('../pages/_slug/pick-location.vue' /* webpackChunkName: "pages/_slug/pick-location" */))
const _3d752cb4 = () => interopDefault(import('../pages/_slug/tempo-agreement.vue' /* webpackChunkName: "pages/_slug/tempo-agreement" */))
const _0297558d = () => interopDefault(import('../pages/_slug/voucher/index.vue' /* webpackChunkName: "pages/_slug/voucher/index" */))
const _04bff31d = () => interopDefault(import('../pages/_slug/order/auth.vue' /* webpackChunkName: "pages/_slug/order/auth" */))
const _a4a70dc4 = () => interopDefault(import('../pages/_slug/order/success.vue' /* webpackChunkName: "pages/_slug/order/success" */))
const _2988164c = () => interopDefault(import('../pages/_slug/morak-auth/_id.vue' /* webpackChunkName: "pages/_slug/morak-auth/_id" */))
const _4f5970d5 = () => interopDefault(import('../pages/_slug/order/_id.vue' /* webpackChunkName: "pages/_slug/order/_id" */))
const _01b15196 = () => interopDefault(import('../pages/_slug/voucher/_id.vue' /* webpackChunkName: "pages/_slug/voucher/_id" */))
const _12463284 = () => interopDefault(import('../pages/_slug/_product.vue' /* webpackChunkName: "pages/_slug/_product" */))
const _7dcf12e4 = () => interopDefault(import('../pages/_slug/product/index.vue' /* webpackChunkName: "pages/_slug/product/index" */))
const _078e2869 = () => interopDefault(import('../pages/_slug/product/_idproduct.vue' /* webpackChunkName: "pages/_slug/product/_idproduct" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/beli",
    component: _9ff2c3a2,
    name: "beli"
  }, {
    path: "/cari",
    component: _4e8bf1c4,
    name: "cari"
  }, {
    path: "/pesan",
    component: _24c8b4af,
    name: "pesan"
  }, {
    path: "/privacy-policy",
    component: _57fef806,
    name: "privacy-policy"
  }, {
    path: "/term-condition",
    component: _1d00e869,
    name: "term-condition"
  }, {
    path: "/verification-promo",
    component: _2c2e1d68,
    name: "verification-promo"
  }, {
    path: "/merchant-pos/privacy-policy",
    component: _1fb9def8,
    name: "merchant-pos-privacy-policy"
  }, {
    path: "/merchant-pos/terms-condition",
    component: _647288c4,
    name: "merchant-pos-terms-condition"
  }, {
    path: "/pesan/info",
    component: _7060a161,
    name: "pesan-info"
  }, {
    path: "/",
    component: _5b839eb3,
    name: "index"
  }, {
    path: "/:slug",
    component: _ed3af0a4,
    name: "slug"
  }, {
    path: "/:slug/cart",
    component: _2f997ff4,
    name: "slug-cart"
  }, {
    path: "/:slug/checkout",
    component: _311d5dcc,
    name: "slug-checkout"
  }, {
    path: "/:slug/info",
    component: _39d0f282,
    name: "slug-info"
  }, {
    path: "/:slug/morak-letter",
    component: _57778e6d,
    name: "slug-morak-letter"
  }, {
    path: "/:slug/order",
    component: _0ea9872d,
    name: "slug-order"
  }, {
    path: "/:slug/pick-location",
    component: _d2841986,
    name: "slug-pick-location"
  }, {
    path: "/:slug/tempo-agreement",
    component: _3d752cb4,
    name: "slug-tempo-agreement"
  }, {
    path: "/:slug/voucher",
    component: _0297558d,
    name: "slug-voucher"
  }, {
    path: "/:slug/order/auth",
    component: _04bff31d,
    name: "slug-order-auth"
  }, {
    path: "/:slug/order/success",
    component: _a4a70dc4,
    name: "slug-order-success"
  }, {
    path: "/:slug/morak-auth/:id?",
    component: _2988164c,
    name: "slug-morak-auth-id"
  }, {
    path: "/:slug/order/:id",
    component: _4f5970d5,
    name: "slug-order-id"
  }, {
    path: "/:slug/voucher/:id",
    component: _01b15196,
    name: "slug-voucher-id"
  }, {
    path: "/:slug/:product",
    component: _12463284,
    children: [{
      path: "",
      component: _7dcf12e4,
      name: "slug-product"
    }, {
      path: ":idproduct",
      component: _078e2869,
      name: "slug-product-idproduct"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
