import Bugsnag from '@bugsnag/js'

function comparePrice(a, b) {
  if (a.price > b.price) return 1
  if (b.price > a.price) return -1

  return 0
}

function compareMinQty(a, b) {
  if (a.min_quantity < b.min_quantity) return 1
  if (b.min_quantity < a.min_quantity) return -1

  return 0
}

// function compareStock(a, b) {
//   if (a.stock < b.stock) return 1
//   if (b.stock < a.stock) return -1
//   return 0
// }

export const mapProductListResponseToProductListData = (data) => {
  try {
    const { product } = data

    let newListItems = product?.rows

    newListItems = newListItems
      .flatMap((item) => {
        try {
          const productUnits = item?.ProductUnits?.length > 0 ? item?.ProductUnits : item?.ParentProduct?.ProductUnits
          const allUnits = productUnits?.map((el) => ({
            id: el.id,
            name: el.unit,
            price: el.price,
            discount_price: el.discount_price,
            ratio: el.ratio,
            active: el.active,
            available_online: el.available_online,
            min_quantity: el.min_quantity,
            wholesale_price: el.wholesale_price,
            isPriceAvailable: !!el.price || !!el.discount_price,
            // TODO:
            // stock: el.stock,
          }))?.sort(comparePrice).sort(compareMinQty) || []

          // TODO:
          // const sortedUnitsByStock = allUnits.sort(compareStock)
          // const baseUnits = sortedUnitsByStock.filter((el) => !el.wholesale_price && el.stock > 0)
          const baseUnits = allUnits.filter((el) => !el.wholesale_price)
          const wholesaleUnits = allUnits.filter((el) => el.wholesale_price)
          let areaPhone = ''
          const productCoverageArea = item.ProductCoverageAreas.find((area) => (area.CoverageArea.id === item.coverage_area_id))
          if (productCoverageArea && productCoverageArea.CoverageArea && productCoverageArea.CoverageArea.phone) areaPhone = productCoverageArea.CoverageArea.phone

          return {
            id: item.id,
            name: item.name,
            description: item.description,
            main_picture_url: item.main_picture_url,
            images: item.ProductImages?.map((img) => ({
              id: img.id,
              url: img.image_url,
              active: img.active,
              featured: img.featured,
            })) || [],
            category: item.ProductCategory
              ? {
                  id: item.ProductCategory.id,
                  name: item.ProductCategory.name,
                }
              : null,
            unit: baseUnits[0],
            listUnit: baseUnits,
            allUnits,
            wholesaleUnits,
            ranked: item.ranked,
            productBought: item.product_bought,
            coverageAreaId: item.coverage_area_id || '',
            coverageAreaIds: item.ProductCoverageAreas?.map((area) => (area.coverage_area_id)) || [],
            productCoverageAreas: item.ProductCoverageAreas || [],
            referenceProductId: item.reference_product_id,
            promoActive: item.promo_active
              ? item.promo_active
              : allUnits.some((u) => u.discount_price > 0),
            areaPhone,
          }
        } catch (error) {
          return []
        }
      })
    // .filter((item) => item.unit.active)
    return {
      count: product.count,
      rows: newListItems,
    }
  } catch (error) {
    if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
      Bugsnag.notify(new Error(error))
    }
  }
}

export const state = () => ({
  // state
  activeTab: 'products',
  profile: null,
  profileOrder: null,
  products: {
    count: 0,
    rows: [],
  },
  resellerProducts: {
    count: 0,
    rows: [],
  },
  category: [],
  paymentMethods: [],
  buyerPaymentMethods: [],
  cart: { items: [], unavailableCartItems: [], paymentMethod: {} },
  detailProduct: null,
  sales: [],
  vouchers: [],
  filteredVouchers: [],
  voucherDetail: null,
  eligibleVoucher: null,
  deliveryFee: null,
})

export const mutations = {
  // mutation
  SET_DATA_MERCHANT(state, data) {
    const s = state
    const { profile } = data
    s.profile = { ...profile }
  },
  SET_DATA_MERCHANT_ORDER(state, data) {
    const s = state
    const { profile } = data
    s.profileOrder = { ...profile }
  },
  SET_DEFAULT_MERCHANT(state) {
    const s = state
    s.profile = null
    s.products = null
    s.category = []
  },
  SET_ACTIVE_TAB(state, data) {
    const s = state
    s.activeTab = data
  },
  SET_PRODUCTS(state, data) {
    const s = state
    s.products = data
  },
  APPEND_PRODUCTS(state, { data, sortBy, orderBy }) {
    const s = state
    const count = s.products && s.products.count ? s.products.count : 0
    let rows = s.products && s.products.rows ? [...s.products.rows, ...data.rows] : data.rows
    if (orderBy === 'price') {
      if (sortBy === 'asc') {
        rows = rows.sort((firstProduct, secondProduct) => (firstProduct.unit.price - secondProduct.unit.price))
      } else if (sortBy === 'desc') {
        rows = rows.sort((firstProduct, secondProduct) => (secondProduct.unit.price - firstProduct.unit.price))
      }
    }
    s.products = { count, rows }
  },
  SET_DETAIL_PRODUCT(state, data) {
    const s = state
    s.detailProduct = data
  },
  SET_CATEGORIES(state, data) {
    const s = state
    s.category = [...data]
  },
  SET_PAYMENT_METHODS(state, data) {
    const s = state
    s.paymentMethods = [...data.filter((d) => d.active === true)]
  },
  SET_BUYER_PAYMENT_METHODS(state, data) {
    const s = state
    s.buyerPaymentMethods = data
  },
  SET_CART(state, data) {
    const s = state
    s.cart = data
  },
  SET_SALES(state, data) {
    const s = state
    s.sales = data
  },
  UPDATE_PRODUCT(state, data) {
    const s = state
    s.products = {
      ...s.products,
      rows: [...data],
    }
  },
  SET_VOUCHERS(state, payload) {
    state.vouchers = payload
  },
  SET_FILTERED_VOUCHERS(state, payload) {
    state.filteredVouchers = payload
    const voucherRedeemId = payload[0]?.id
    if (!!voucherRedeemId) {
      state.vouchers = state.vouchers.filter(({ id }) => id !== voucherRedeemId)
    }
  },
  SET_VOUCHER_DETAIL(state, payload) {
    state.voucherDetail = payload
  },
  SET_ELIGIBLE_VOUCHER(state, payload) {
    state.eligibleVoucher = payload
  },
  SET_DELIVERY_FEES(state, payload) {
    state.deliveryFee = [...payload.filter((d) => d.active === true)]
  },
}

export const getters = {
  // getters
  getProfile(state) {
    return state.profile
  },
  getProductsCount(state) {
    return state.products?.count
  },
  getProducts(state) {
    return state.products?.rows || []
  },
  getResellerProducts(state) {
    return state.resellerProducts?.rows || []
  },
  getDetailProduct(state) {
    return state.detailProduct?.rows || []
  },
  getCategory(state) {
    return state.category
  },
  getPaymentMethods(state) {
    return state.paymentMethods
  },
  getBuyerPaymentMethods(state) {
    return state.buyerPaymentMethods
  },
  getMerchantName(state) {
    const { profile } = state
    if (profile && profile.User) {
      return profile.User.username
    } else {
      return ''
    }
  },
  getMerchantInfo(state) {
    const { profile } = state
    return profile
  },
  getMerchantOrderInfo(state) {
    const { profileOrder } = state
    return profileOrder
  },
  getActiveTab(state) {
    return state.activeTab
  },
  getCart(state) {
    return state.cart
  },
  getSales(state) {
    return state.sales
  },
  getVouchers(state) {
    return state.vouchers
  },
  getFilteredVouchers(state) {
    return state.filteredVouchers
  },
  getVoucherDetail(state) {
    return state.voucherDetail
  },
  getEligibleVoucher(state) {
    return state.eligibleVoucher
  },
  getDeliveryFee(state) {
    return state.deliveryFee
  },
}

export const actions = {
  // actions
  async getDataMerchant({ commit }, { slug, coverageAreaId }) {
    try {
      const url = '/guest/seller-profile'
      const params = { username: slug }
      if (coverageAreaId) params.coverage_area = coverageAreaId
      const res = await this.$axios.$get(url, { params })

      if (res && res.profile) {
        const merchantProfile = res.profile;
        merchantProfile.is_domain_active = merchantProfile.is_domain_active === true || merchantProfile.is_domain_active === null || merchantProfile.is_domain_active === undefined
        await commit('SET_DATA_MERCHANT', res)
        this.$cookies.remove('gdmp')
        this.$cookies.set('gdmp', {
          ...merchantProfile.User,
          is_reseller: merchantProfile.reseller && merchantProfile.digital_payments,
          address: merchantProfile.address,
        }, {
          path: '/',
          maxAge: 2592000,
        })
        return merchantProfile;
      } else {
        return null
      }
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
      return null;
    }
  },

  async getMerchant({ commit }, { slug, coverageAreaId }) {
    try {
      const url = '/guest/seller-profile/'
      const params = { username: slug }
      if (coverageAreaId) params.coverage_area = coverageAreaId
      const res = await this.$axios.$get(url, { params })

      if (res && res.profile) {
        await commit('SET_DATA_MERCHANT_ORDER', res)
        return res.profile
      }
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async counterVisitor({ commit }, { slug }) {
    try {
      const url = '/guest/seller-count'
      await this.$axios.$get(url, {
        params: {
          username: slug,
        },
      })
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async getDataProduct({ commit, getters }, payload) {
    commit('SET_LOADING', true, { root: true })
    try {
      const response = await this.$axios.$get('/guest/seller-product/', {
        params: {
          username: payload.username,
          limit: payload.limit,
          offset: payload.offset,
          id: payload.id,
          name: payload.name,
          product_category_id: payload.product_category_id,
          promo_active: payload.promo_active,
          is_new: payload.is_new,
          order_by: payload.order_by,
          sort_by: payload.sort_by,
          coverage_area_id: payload.coverage_area_id,
          ...(payload.filtered_product_category && { filtered_product_category: true }),
        },
      })

      if (response && response.product) {
        const data = mapProductListResponseToProductListData(response)
        if (payload.offset > 0) {
          await commit('APPEND_PRODUCTS', {
            data,
            sortBy: payload.sort_by,
            orderBy: payload.order_by,
          })
        } else {
          await commit('SET_PRODUCTS', data)
        }
        commit('SET_LOADING', false, { root: true })

        if (response?.product?.count === 0 && getters.getCategory.length === 0) {
          commit(
            'SET_EMPTY_STATE',
            {
              title: !!payload.id ? 'Produk Tidak Ditemukan' : 'Toko ini masih kosong',
              desc: !!payload.id ? 'Tidak ada produk yang anda cari :(' : 'Hubungi penjual untuk info lebih lanjut!',
            },
            { root: true }
          )
        }
        return data
      }
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
        commit('SET_LOADING', false, { root: true })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async getResellerDataProduct({ commit, getters }, payload) {
    commit('SET_LOADING', true, { root: true })
    try {
      const response = await this.$axios.$get('/guest/reseller-products/', {
        params: {
          username: payload.username,
          limit: payload.limit,
          offset: payload.offset,
          id: payload.id,
          name: payload.name,
          product_category_id: payload.product_category_id,
          promo_active: payload.promo_active,
          is_new: payload.is_new,
          order_by: payload.order_by,
          sort_by: payload.sort_by,
          coverage_area_id: payload.coverage_area_id,
          ...(payload.filtered_product_category && { filtered_product_category: true }),
          // filtered_product_category: payload.filtered_product_category,
        },
      })

      if (response && response.product) {
        const data = mapProductListResponseToProductListData(response)
        if (payload.offset > 0) {
          await commit('APPEND_PRODUCTS', {
            data,
            sortBy: payload.sort_by,
            orderBy: payload.order_by,
          })
        } else {
          await commit('SET_PRODUCTS', data)
        }
        commit('SET_LOADING', false, { root: true })

        if (response?.product?.count === 0 && getters.getCategory.length === 0) {
          commit(
            'SET_EMPTY_STATE',
            {
              title: !!payload.id ? 'Produk Tidak Ditemukan' : 'Toko ini masih kosong',
              desc: !!payload.id ? 'Tidak ada produk yang anda cari :(' : 'Hubungi penjual untuk info lebih lanjut!',
            },
            { root: true }
          )
        }
        return data
      }
    } catch (error) {
      if (error.response) {
        commit('SET_LOADING', false, { root: true })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async getDataCategory({ commit }, { slug, coverageAreaId }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = '/guest/seller-category'
      const res = await this.$axios.$get(url, {
        params: {
          username: slug,
          ...(coverageAreaId && { coverage_area_id: coverageAreaId }),
        }
      })
      if (res) {
        const mappedCategories = res.map((cat) => ({
          ...cat,
          active: cat.active ?? true,
          description: cat.description ?? null,
          icon: cat.icon ? cat.icon : '/img/category/default.svg',
        }))
        await commit('SET_CATEGORIES', mappedCategories)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
        commit('SET_LOADING', false, { root: true })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async getResellerCategory({ commit }, { coverageAreaId, filterProductCategory }) {
    if (!coverageAreaId) {
      await commit('SET_CATEGORIES', [])
      return false
    }

    commit('SET_LOADING', true, { root: true })
    try {
      const params = {
        ...(coverageAreaId && { coverage_area_id: coverageAreaId }),
        ...(filterProductCategory && { filtered_product_category: true }),
        // filtered_product_category: filterProductCategory,
      }
      const res = await this.$axios.$get('/guest/reseller-category/', { params })
      if (res) {
        const mappedCategories = res.map((cat) => ({
          ...cat,
          active: cat.active ?? true,
          description: cat.description ?? null,
          icon: cat.icon ? cat.icon : '/img/category/default.svg',
        }))
        await commit('SET_CATEGORIES', mappedCategories)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      if (error.response) {
        await commit('SET_DEFAULT_MERCHANT')
        commit('SET_LOADING', false, { root: true })
      }
    }
  },

  async getDataPaymentMethod({ commit }, { slug, coverageAreaId }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = '/guest/admin-fee'
      const params = {
        username: slug,
        ...(coverageAreaId && { coverage_area_id: coverageAreaId })
      }
      const res = await this.$axios.$get(url, { params })

      if (res) {
        await commit('SET_PAYMENT_METHODS', res)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async getBuyerPaymentMethod({ commit }, { token, sellerId, coverageAreaId }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = '/buyer/payment-method'
      const params = {
        seller_id: sellerId,
        ...(coverageAreaId && { coverage_area_id: coverageAreaId })
      }
      const res = await this.$axios.$get(url, {
        headers: { 'x-access-token': token },
        params
      })

      if (res) {
        await commit('SET_BUYER_PAYMENT_METHODS', res)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async initCart({ commit }, { payload, username, shouldRefetchCartItems = false }) {
    commit('SET_LOADING', true, { root: true })
    const cartData = JSON.parse(payload)
    if (username) {
      const newCart = [];
      const unavailableCartItems = [];
      const unavailableProductsFromLocalStorage = localStorage.getItem('unavailable-cart-products')
      const checkedProducts = new Map()

      // personal or seller products
      const productIds = cartData.items.filter(({ referenceProductId }) => !referenceProductId).map(({ id }) => id)
      if (productIds.length) {
        const response = await this.$axios.$get('/guest/seller-product', {
          params: {
            username,
            id: productIds.toString(),
            limit: productIds.length,
          },
        })
        if (response?.product && Array.isArray(response?.product?.rows)) {
          response?.product?.rows.forEach((product) => { checkedProducts[product.id] = product })
        }
      }

      // reseller products
      const resellerProductIds = cartData.items.filter(({ referenceProductId }) => !!referenceProductId).map(({ id }) => id)
      if (resellerProductIds?.length) {
        const resellerAreaData = await this.$cookies.get('gdb')
        const coverageAreaId = resellerAreaData.coverageAreaId ?? ''
        if (!!coverageAreaId) {
          const response = await this.$axios.$get('/guest/reseller-products', {
            params: {
              username,
              id: resellerProductIds.toString(),
              coverage_area_id: coverageAreaId,
              limit: resellerProductIds.length,
            },
          })
          if (response?.product && Array.isArray(response?.product?.rows)) {
            response?.product?.rows.forEach((product) => { checkedProducts[product.id] = product })
          }
        }
      }

      const numCartVisit = await localStorage.getItem(`numCartVisit-${username}`)
      for (const cartItem of cartData.items) {
        const mappedProducts = checkedProducts[cartItem.id] && mapProductListResponseToProductListData({ product: { rows: [checkedProducts[cartItem.id]] } })
        const productFromServer = !!mappedProducts && !!mappedProducts.rows.length && mappedProducts.rows[0]
        const unitFromServer = !!productFromServer && productFromServer.allUnits.length && productFromServer.allUnits.find(
          (u) => u.name === cartItem.selectedUnit && u.min_quantity <= cartItem.qty)
        const isItemPriceChanged = unitFromServer && (unitFromServer.price !== cartItem.unit.price || unitFromServer.discount_price !== cartItem.unit.discount_price)

        // TODO:
        // const isStockChanged = unitFromServer && (unitFromServer.stock !== cartItem.selectedUnit.stock)
        // const isStockChanged = true
        // const unitNewStock = 3

        if (!productFromServer || !unitFromServer) {
          cartItem.isCartAvailable = false
        } else {
          cartItem.isCartAvailable = true

          if (!!shouldRefetchCartItems) {
            if (isItemPriceChanged) {
              cartItem.isPriceChanged = true
              // assign previous selected unit with the current selected unit
              cartItem.previousUnit = cartItem.unit
              // update latest unit & price
              cartItem.unit = unitFromServer
              cartItem.allUnits = productFromServer.allUnits
              cartItem.listUnit = productFromServer.listUnit
              const prevPrice = cartItem.previousUnit.discount_price || cartItem.previousUnit.price
              const newPrice = cartItem.unit.discount_price || cartItem.unit.price
              cartItem.amountChanged = newPrice - prevPrice
            }

            // TODO:
            // if (isStockChanged) {
            //   cartItem.isStockChanged = isStockChanged
            //   cartItem.unit.previousStock = cartItem.unit.stock
            //   cartItem.unit.stock = unitNewStock
            // }

            // reset product price update ticker, the mark will only be shown one time when buyer open the cart
            if (numCartVisit >= 1 && !isItemPriceChanged) {
              delete cartItem.isPriceChanged
              delete cartItem.previousUnit
              delete cartItem.amountChanged
            }
          }
        }

        if (numCartVisit >= 1) localStorage.removeItem(`numCartVisit-${username}`)

        if (unavailableProductsFromLocalStorage && unavailableProductsFromLocalStorage.includes(cartItem.id)) {
          unavailableCartItems.push({ ...cartItem, unavailable: true })
        } else {
          newCart.push(cartItem);
        }
      }

      commit('SET_CART', { items: newCart, unavailableCartItems })
      await commit('SET_LOADING', false, { root: true })
      return { items: newCart };
      // })
    } else {
      await commit('SET_CART', cartData)
      await commit('SET_LOADING', false, { root: true })
      return cartData
    }
  },

  async addCart({ commit }, { payload, currentCart, isUpdate }) {
    const cart = JSON.parse(currentCart)

    // update cart
    let newCart = { ...cart }
    let isNew = true
    let update = []

    if (newCart && newCart?.items?.length > 0) {
      update = newCart.items.map((el) => {
        const newObj = el
        if (el.id === payload.id && el.unit.name === payload.unit.name) {
          const baseUnit = el.listUnit.find(
            (el) => el.name === newObj.unit.name
          )
          if (payload.qty === 1) {
            newObj.price = baseUnit.price
            newObj.unit.price = baseUnit.price
          } else {
            newObj.price = payload.unit.price
            newObj.unit.price = payload.unit.price
          }
          if (isUpdate) {
            newObj.qty = payload.qty
          } else {
            newObj.qty += payload.qty
          }
          isNew = false
        }
        return newObj
      })
    } else {
      update = newCart.items
    }

    // simply, isNew then push to array items
    if (isNew) {
      newCart = { items: [...[payload], ...update] }
    } else {
      newCart = { items: [...update] }
    }

    // masukkan ke mutation
    await commit('SET_CART', newCart)

    return newCart
  },

  async deleteCart({ commit }, { cartId, currentCart }) {
    const cart = JSON.parse(currentCart)

    // update cart
    let newCart = { ...cart }
    const remove = newCart.items.filter((item) => item.cartId !== cartId)
    newCart = { items: [...remove] }

    // masukkan ke mutation
    await commit('SET_CART', newCart)

    return newCart
  },

  async deleteAllCart({ commit }, { payload, currentCart }) {
    const cart = JSON.parse(currentCart)

    // update cart
    let newCart = { ...cart }

    if (newCart.items.length === payload.length) {
      newCart = { items: [] }
    } else {
      payload.forEach((id) => {
        const remove = newCart.items.filter((item) => item.cartId !== id)
        newCart = { items: [...remove] }
      })
    }

    // masukkan ke mutation
    await commit('SET_CART', newCart)

    return newCart
  },

  async fetchSalesList({ commit }, { username, coverageAreaIds }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = `/guest/sales/?username=${username}&coverage_area_ids=${coverageAreaIds}`
      const data = await this.$axios.$get(url)
      commit('SET_SALES', data)
      return data
    } catch (error) {
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async setPaymentMethodOnCart({ commit, state }, { paymentMethod }) {
    await commit('SET_CART', {
      ...state.cart,
      paymentMethod,
    })
  },

  async fetchProductDetail({ commit }, payload) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = `/guest/seller-product/?username=${payload.username}${payload.name ? '&name=' + payload.name : ''}${payload.product_category_id ? '&product_category_id=' + payload.product_category_id : ''}&id=${payload.id}&limit=${payload.limit}&offset=${payload.offset}`
      const response = await this.$axios.$get(url)

      if (response && response.product) {
        const data = mapProductListResponseToProductListData(response)
        await commit('SET_DETAIL_PRODUCT', data)
        commit('SET_LOADING', false, { root: true });
        return data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response) {
        commit('SET_LOADING', false, { root: true })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },

  async fetchResellerProductDetail({ commit }, { id, username }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = `/guest/reseller-product/${id}?username=${username}`
      const response = await this.$axios.$get(url)
      if (response && response.product) {
        const data = mapProductListResponseToProductListData(response)
        await commit('SET_DETAIL_PRODUCT', data)
        commit('SET_LOADING', false, { root: true })
        return data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response) {
        commit('SET_LOADING', false, { root: true })
      }
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
  async fetchVouchers({ commit }, { username, token, voucherCode, limit, offset }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const res = await this.$axios.$get('/buyer/voucher', {
        headers: { 'x-access-token': token },
        params: {
          username,
          voucher_code: voucherCode,
          limit,
          offset,
        }
      })

      if (res && res?.rows) {
        if (voucherCode) {
          await commit('SET_FILTERED_VOUCHERS', res?.rows)
          commit('SET_LOADING', false, { root: true })
        } else {
          await commit('SET_VOUCHERS', res?.rows)
          commit('SET_LOADING', false, { root: true })
        }
      }
    } catch (error) {
      Bugsnag.notify(new Error(error))
    }
  },
  async fetchVoucherDetail({ commit }, { token, voucherId }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const res = await this.$axios.$get(`/buyer/voucher/${voucherId}`, {
        headers: { 'x-access-token': token },
      })

      if (res) {
        await commit('SET_VOUCHER_DETAIL', res)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      Bugsnag.notify(new Error(error))
    }
  },
  async checkVoucher({ commit }, { token, voucherId, username, adminFeeId, placeId, coverageAreaId, products }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const res = await this.$axios.$post('/buyer/check-voucher', {
        id: voucherId,
        username,
        admin_fee_id: adminFeeId,
        place_id: placeId,
        coverage_area_id: coverageAreaId,
        products,
      }, {
        headers: { 'x-access-token': token },
      })

      if (res) {
        await commit('SET_ELIGIBLE_VOUCHER', res)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      Bugsnag.notify(new Error(error))
      throw error
    }
  },

  async getDataDeliveryFees({ commit }, { slug }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const url = `/guest/delivery-fee?username=${slug}`
      const res = await this.$axios.$get(url)

      if (res) {
        await commit('SET_DELIVERY_FEES', res)
        commit('SET_LOADING', false, { root: true })
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
        Bugsnag.notify(new Error(error))
      }
    }
  },
}
