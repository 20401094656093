import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_044ee016 from 'nuxt_plugin_plugin_044ee016' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_0a10c019 from 'nuxt_plugin_plugin_0a10c019' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_libpluginddc5ba22_34cd5e04 from 'nuxt_plugin_libpluginddc5ba22_34cd5e04' // Source: ./lib.plugin.ddc5ba22.js (mode: 'all')
import nuxt_plugin_recaptcha_7af4d90c from 'nuxt_plugin_recaptcha_7af4d90c' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_nuxtgooglemaps_7d9f3d6c from 'nuxt_plugin_nuxtgooglemaps_7d9f3d6c' // Source: ./nuxt-google-maps.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_5c813b94 from 'nuxt_plugin_nuxtuseragent_5c813b94' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_25746c33 from 'nuxt_plugin_cookieuniversalnuxt_25746c33' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_workbox_4d0d40e7 from 'nuxt_plugin_workbox_4d0d40e7' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_d2329e0c from 'nuxt_plugin_metaplugin_d2329e0c' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_c8e35324 from 'nuxt_plugin_iconplugin_c8e35324' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_7f03428b from 'nuxt_plugin_axios_7f03428b' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_06611f16 from 'nuxt_plugin_deviceplugin_06611f16' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_bugsnag_2200fd98 from 'nuxt_plugin_bugsnag_2200fd98' // Source: ../plugins/bugsnag.js (mode: 'all')
import nuxt_plugin_vuetifythemecache_559998ce from 'nuxt_plugin_vuetifythemecache_559998ce' // Source: ../plugins/vuetify-theme-cache.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_helper_850e665a from 'nuxt_plugin_helper_850e665a' // Source: ../plugins/helper.js (mode: 'all')
import nuxt_plugin_vuegtag_44730e72 from 'nuxt_plugin_vuegtag_44730e72' // Source: ../plugins/vue-gtag.js (mode: 'all')
import nuxt_plugin_items_083ea57e from 'nuxt_plugin_items_083ea57e' // Source: ../plugins/apps/items.js (mode: 'all')
import nuxt_plugin_snackbar_4abe1baa from 'nuxt_plugin_snackbar_4abe1baa' // Source: ../plugins/apps/snackbar.js (mode: 'all')
import nuxt_plugin_aos_caeae0de from 'nuxt_plugin_aos_caeae0de' // Source: ../plugins/aos (mode: 'client')
import nuxt_plugin_vuemasonry_087bf870 from 'nuxt_plugin_vuemasonry_087bf870' // Source: ../plugins/vue-masonry (mode: 'client')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'client')
import nuxt_plugin_vcalendar_299c62ab from 'nuxt_plugin_vcalendar_299c62ab' // Source: ../plugins/v-calendar (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","title":"CrediMart - Belanja Grosir Online untuk Kebutuhan Toko dan Warung","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fimg\u002Fcredimart\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=G-MJD2ME6MZQ"},{"type":"text\u002Fjavascript","charset":"utf-8","innerHTML":"window.dataLayer = window.dataLayer || [];\n        function gtag(){dataLayer.push(arguments);}\n        gtag('js', new Date());\n        gtag('config', 'G-MJD2ME6MZQ');"},{"type":"text\u002Fjavascript","charset":"utf-8","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n        'https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n        })(window,document,'script','dataLayer','GTM-KK5KFD5');"},{"type":"text\u002Fjavascript","charset":"utf-8","innerHTML":"!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){\n          (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},\n          t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,\n          o.src=\"https:\u002F\u002Fwebsdk.appsflyer.com?\"+(c.length\u003E0?\"st=\"+c.split(\",\").sort().join(\",\")+\"&\":\"\")+(i.length\u003E0?\"af_id=\"+i:\"\"),\n          p.parentNode.insertBefore(o,p)}(window,document,\"script\",0,\"AF\",\"pba\",{pba: {webAppId: \"b3ee81d9-8946-40ce-a6e2-015b3c1969f7\"}})"}],"__dangerouslyDisableSanitizers":["script"],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_044ee016 === 'function') {
    await nuxt_plugin_plugin_044ee016(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0a10c019 === 'function') {
    await nuxt_plugin_plugin_0a10c019(app.context, inject)
  }

  if (typeof nuxt_plugin_libpluginddc5ba22_34cd5e04 === 'function') {
    await nuxt_plugin_libpluginddc5ba22_34cd5e04(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_7af4d90c === 'function') {
    await nuxt_plugin_recaptcha_7af4d90c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtgooglemaps_7d9f3d6c === 'function') {
    await nuxt_plugin_nuxtgooglemaps_7d9f3d6c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_5c813b94 === 'function') {
    await nuxt_plugin_nuxtuseragent_5c813b94(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_25746c33 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_25746c33(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_4d0d40e7 === 'function') {
    await nuxt_plugin_workbox_4d0d40e7(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_d2329e0c === 'function') {
    await nuxt_plugin_metaplugin_d2329e0c(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_c8e35324 === 'function') {
    await nuxt_plugin_iconplugin_c8e35324(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7f03428b === 'function') {
    await nuxt_plugin_axios_7f03428b(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_06611f16 === 'function') {
    await nuxt_plugin_deviceplugin_06611f16(app.context, inject)
  }

  if (typeof nuxt_plugin_bugsnag_2200fd98 === 'function') {
    await nuxt_plugin_bugsnag_2200fd98(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetifythemecache_559998ce === 'function') {
    await nuxt_plugin_vuetifythemecache_559998ce(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_helper_850e665a === 'function') {
    await nuxt_plugin_helper_850e665a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuegtag_44730e72 === 'function') {
    await nuxt_plugin_vuegtag_44730e72(app.context, inject)
  }

  if (typeof nuxt_plugin_items_083ea57e === 'function') {
    await nuxt_plugin_items_083ea57e(app.context, inject)
  }

  if (typeof nuxt_plugin_snackbar_4abe1baa === 'function') {
    await nuxt_plugin_snackbar_4abe1baa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_aos_caeae0de === 'function') {
    await nuxt_plugin_aos_caeae0de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemasonry_087bf870 === 'function') {
    await nuxt_plugin_vuemasonry_087bf870(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vcalendar_299c62ab === 'function') {
    await nuxt_plugin_vcalendar_299c62ab(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
